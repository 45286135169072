<style>
    :global(a) {
        color: #1e90ff;
        text-decoration: underline;
    }

    :global(a:hover) {
        text-decoration: none;
    }

    :global(label) {
        display: block;
    }

    :global(button, input, textarea) {
        font-size: inherit;

        transition: all 250ms ease;
    }

    :global(input, textarea) {
        width: 100%;
    }

    :global(button) {
        display: inline-block;

        padding: 8px 12px;
        border: none;
        border-radius: 2px;
        background-color: dodgerblue;
        color: white;
        cursor: pointer;

        transition: all 300ms ease;
    }

    :global(button.small) {
        padding: 6px 8px;
        font-size: smaller;
    }

    :global(button.link) {
        padding: 0;
        background-color: unset;
        text-decoration: underline;
        color: #1e90ff;
    }

    :global(button.link:hover) {
        text-decoration: none;
    }

    :global(button:hover:not(:disabled)) {
        filter: brightness(1.1);
    }

    :global(button:disabled) {
        filter: grayscale(1) brightness(1.2);
        cursor: not-allowed;
    }

    :global(button.red) {
        background-color: tomato;
    }

    :global(input[type="text"], input[type="search"], input[type="password"], textarea) {
        padding: 8px;
        border: 0px solid #999999;
        border-bottom-width: 1px;
    }

    :global(input[type="text"]:focus, input[type="search"]:focus, input[type="password"]:focus, textarea:focus) {
        border-color: #1e90ff;
    }

    :global(
            input[type="text"]:disabled,
            input[type="search"]:disabled,
            input[type="password"]:disabled,
            textarea:disabled
        ) {
        filter: grayscale(1) brightness(1.5);
        cursor: not-allowed;
    }

    :global(textarea) {
        border-width: 1px;
    }

    :global(.input-error) {
        padding: 4px 12px;
        font-size: small;
        color: #ff6347;
    }

    :global(.text-center) {
        text-align: center;
    }

    :global(.container) {
        padding: 10px;
    }

    :global(.grid-box-1) {
        padding: 20px;

        display: grid;
        grid-gap: 16px;

        background-color: #ffffff;
        box-shadow: 0 0 5px #cccccc;
    }

    :global(.actions-row) {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
    }

    /* Phone ---------------------------------------------------------------- */
    @media (max-width: 719.95px) {
        :global(.desktop-only) {
            display: none !important;
        }

        :global(input[type="text"], input[type="search"], input[type="password"], textarea) {
            padding: 16px;
        }

        :global(body, input[type="text"], input[type="search"], input[type="password"], textarea, button) {
            font-size: 24px;
        }

        :global(button:not(.link)) {
            padding: 16px 20px;
        }

        :global(button:not(.link).small) {
            padding: 12px 16px;
        }

        :global(button:not(.link).small) {
            font-size: inherit;
        }

        :global(.container) {
            padding: 0;
        }
    }
</style>
